import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { TooltipsBasic, TooltipsPlacement, TooltipsWithHtml } from "../../examples/components/ToolTips";
export const query = graphql`
  query OverlayQuery {
    Tooltip: componentMetadata(displayName: { eq: "Tooltip" }) {
      ...ComponentApi_metadata
    }
    OverlayTrigger: componentMetadata(displayName: { eq: "OverlayTrigger" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  <ul>
    <li>
      The <code>&lt;Tooltip&gt;</code> component do not position itself. Instead
      the <code>&lt;Overlay&gt;</code> (or <code>&lt;OverlayTrigger&gt;</code>)
      components, inject <code>ref</code> and <code>style</code> props.
    </li>
    <li>
      Tooltip expects specific props injected by the{" "}
      <code>&lt;Overlay&gt;</code> component
    </li>
    <li>
      Tooltips for <code>disabled</code> elements must be triggered on a wrapper
      element.
    </li>
    <li>
      <code>Overlay</code> is the fundamental component for positioning and
      controlling tooltip visibility. It's a wrapper around Popper.js, that adds
      support for transitions, and visibility toggling.
    </li>
  </ul>
    </Overview>
    <CodeBlock title="Tooltip examples" code={TooltipsBasic} mdxType="CodeBlock">
  You can pass the <code>Overlay</code> injected props directly to the Tooltip
  component as shown in{" "}
  <a href="https://react-bootstrap-v4.netlify.app/components/overlays/#tooltips" target="_blank" rel="noopener">
    React Bootstrap
  </a>{" "}
  Or pass a Tooltip element to <code>OverlayTrigger</code> instead.
    </CodeBlock>
    <CodeBlock title="Tooltip placement" code={TooltipsPlacement} mdxType="CodeBlock">
  Use <code>placement</code> prop on <code>OverlayTrigger</code> to set the
  position for tooltip.
    </CodeBlock>
    <CodeBlock title="Tooltip with HTML" code={TooltipsWithHtml} mdxType="CodeBlock">
  <code>Tooltip</code> by default supports custom HTML.
    </CodeBlock>
    <LinkedHeading h="2" id="overlays-api" className="h1" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.OverlayTrigger} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.Tooltip} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      